.fondoHistoria{
    background-color: #3980bf;
}
.contenedorTotalHistoria{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3rem 0 0 0;
}
.contenedorSeccion{
    padding: 2rem 2rem 0 2rem;
    transform: translateY(-20px);
}
.imgHistoria{
    background-image: url('../../../Multimedia/fondoHistoria.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 60vh;
}
.imgMision{
    border-radius: 25px 0 0 25px;
    height: 55rem;
    width: 35vw;
    object-fit: cover;
}
.numeroSeccion{
    font-size: 5rem;
    position: absolute;
    transform: translateY(-110px);
    color: rgba(0, 0, 0, 0.151);
}
.tituloSection{
    font-size: 3rem;
    margin-bottom: 0;
    color: #fff;
    position: relative;
}
.parrafoSeccion{
    font-size: 1.2rem;
    margin: 0 2rem;
    color: rgb(215, 215, 215);
}
.listaValores{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    padding: 2rem 0;
}
.contenedorMisionImg{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 3rem;
    margin-top: 2rem;
}
.listaValores div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(215, 215, 215);
    font-size: 1.2rem;
    width: 13rem;
}
.listaValores div img, .visionImg{
    border-radius: 50%;
    width: 3rem;
}

.mision, .vision{
    margin-bottom: 8rem;
}

@media (max-width: 950px)  {
    .valores{
        padding: 0;
        margin: 0;
    }
    .imgMision{
        display: none;
    }
    .listaValores div{
        width: 15rem;
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
    }
    .tituloSection{
        text-align: center;
        font-size: 4rem;
    }
    .parrafoSeccion{
        font-size: 1.5rem;
        text-align: center;
    }
    .mision, .vision{
        margin-bottom: 6rem;
    }
}
@media (max-width: 700px){
    .imgHistoria{
        height: 40vh;
    }
    .tituloSection{
        font-size: 2rem;
    }
    .numeroSeccion{
        font-size: 5rem;
    }
    .parrafoSeccion{
        font-size: 1.2rem;
    }
    .listaValores div{
        width: 13rem;
        padding: 0;
        margin: 0;
        font-size: 1.2rem;
    }
    .contenedorSeccion{
        padding: 0.5rem;
    }
}

@media (max-width: 450px){
    .numeroSeccion{
        font-size: 4rem;
    }
    .listaValores div img{
        border-radius: 50%;
        width: 2rem;
    }
    .listaValores div{
        font-size: 0.8rem;
        width: 10rem;
    }
    .sinImagen{
        display: none;
    }
    .contenedorMisionImg{
        margin-top: 1rem;
        margin-left: 0;
    }
}