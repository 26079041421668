.contenedorHeader{
    background-image: url('../../../Multimedia/fondoHeader.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 0 4rem;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contenedorHeader h3{
    color: #b5d6ff;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    text-shadow: 2px 2px 3px #000000;
    margin: 0 1rem;
}

.contenedorHeader p{
    color: #fff;
    font-weight: bold;
    font-size: 1.7rem;
    text-shadow: 2px 2px #000;
    margin: 0;
}

@media (max-width: 897px)  {
    .contenedorHeader{
        height: 40vh;
    }
}
@media (max-width: 700px){
    .contenedorHeader{
        height: 30vh;
        background-size: cover;
        background-attachment: scroll;
        background-position: 0;
    }
    .contenedorHeader h3{
        font-size: 1.3rem;
    }
}

 