.contenedorRecluting{
    display: flex;
    flex-direction: row;
    
    width: 100vw;
    height: auto;
}

.hijo1ContenedorRecluting{
    width: 50vw;
    height: auto;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0 10px;
}

.hijo2ContenedorRecluting{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: auto;
    margin: 2rem;
    border-radius: 10px;
}

.tituloCursoRecluting{
    font-size: xx-large;
    color: #3981bf;
    text-align: center;
    margin-top: 0;
}

.parrafoCursoRecluting{
    font-size: x-large;
    color: rgb(63, 63, 63);
    font: bold;
    padding-left: 1rem;
}

.botonCurso{
    background: #3981bf;
    border: none;
    padding: 10px 40px;
    border-radius: 15px;
    color: #ffffff;
    font-size: 1.1rem;
    font: lighter;
    margin-top: 5px;
    text-decoration: none;
}

.botonCurso:hover{
    cursor: pointer;
    color: #164975;
    background: #b5d3ee;
}

.aclaracionCurso{
    margin-bottom: 1rem;
    padding-left: 2.5rem;
    width: 100%;
}

.contenedorPrincipalTrabjar{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5rem;
    background-color: #3981bf;
    height: auto;
    padding-bottom: 2rem;
}

.contenedorTrabjar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    height: auto;
}

.imgRecluting{
    height: 50vh;
    background-color: #fff;
    padding: 4rem;
    border-radius: 20% 30%;
}

.contenedorTrabjar h3{
    color: #ffffff;
    font-size: xx-large;
    margin-bottom: 5rem;
}

.itemFormRecruiter{
    display: flex;
    flex-direction: column;
    color: white;
}

.itemFormRecruiter p{
    color: rgb(255, 38, 0);
    font: bold;
}

.itemFormRecruiter label{
    font-size: large;
    margin-top: 1rem;
}

.btnFormRecriter{
    background: #ffffff;
    border: none;
    padding: 10px 40px;
    border-radius: 15px;
    color: #3981bf;
    font-size: 1.1rem;
    font: lighter;
    margin-top: 5px;
}

.btnFormRecriter:hover{
    cursor: pointer;
    color: #164975;
    background: #b5d3ee;
}

.inputRectuiter{
    margin-top: 0.2rem !important;
    margin-bottom: 0.8rem !important;
    width: 35vw !important;
    height: 2rem !important;
    border: none !important;
    border-bottom: 2px solid #fff !important;
    outline: none !important;
    background-color: #3981bf !important;
    color: rgb(0, 0, 0) !important;
    font-size: larger !important;
}

@media (min-width: 1080px) {

}

@media (max-width: 897px) {
    .contenedorRecluting{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .hijo1ContenedorRecluting{
        width: 100vw;
        justify-content: center;
        align-items: center;
    }
    .hijo2ContenedorRecluting{
        width: 100vw;
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .parrafoCursoRecluting{
        padding: 0 6rem;
    }
    .tituloCursoRecluting{
        padding: 0 3rem;
    }

    .imgRecluting{
        display: none;
    }
    .inputRectuiter{
        width: 70vw !important;
    }
    .btnFormRecriter{
        width: 70vw;
        margin-top: 2rem;
    }
}

@media (max-width: 700px){
 .videoRecluting{
    width: 70vw
 }
 .parrafoCursoRecluting{
    padding: 0 2rem;
}
.tituloCursoRecluting{
    padding: 0 1rem;
}
}