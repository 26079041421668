#CasosCarrusel {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    font-size: 14px;
    color: #000;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
   margin-bottom: 2rem;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

.contenedroSwiper{
  border-radius: 5px;
  /* margin-bottom: 3rem;
  background-color: #3981bf; */
}
.contenedorCaso{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: center;
    margin: 2vw 0;
    font-size: 0.8rem;
    /* color: #fff; */
}
.imgCaso{
    width: 5rem;
    border-radius: 50%;
}
.contenedorTextoCaso{
    font-size: 1rem;
    padding: 3rem 1rem;
    border-radius: 25px 25px 25px 3px;
    background-color: #3980bf;
    color: #fff;
}
.lineaSeparacion{
  height: 3px;
  width: 80vw;
  background-color: #3980bf;
  margin: auto auto;
  border-radius: 50%;
  margin: 5rem auto;
}
.tituloCasos{
  font-size: 1.5rem;
  text-align: center;
  color: #3980bf;
  font-weight: bold;
}
.tituloCaso{
    font-size: 1.4rem;
    margin: 0.7rem 0 0 0;
    padding: 0;
    color: #3980bf;
}
.subtituloCaso{
    font-size: 1rem;
    margin: 0;
    padding: 0;
    color: #3980bf;
}
.contenedorFotoyTitulo{
  display: flex;
  flex-direction: row;
}
.contenedorNombreyTrabajo{
  margin-left: 1.5rem;
}

@media (max-width: 700px){
  .tituloCaso{
    font-size: 1rem;
    margin: 0.7rem 0 0 0;
    padding: 0;
}
.subtituloCaso{
    font-size: 0.7rem;
    color:rgba(58, 58, 58, 0.99);
    margin: 0;
    padding: 0;
}
.contenedorTextoCaso{
  font-size: 0.7rem;
    
}
}
@media (max-width: 450px){
 .contenedroSwiper{
  width: 80%;
 }
 .contenedorTextoCaso{
  margin: 1rem;
 }
 .imgCaso{
  margin-left: 1rem;
 }
 .tituloCasos{
  font-size: 1.2rem;
 }
}