.contenedorNavBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    list-style: none;
}

.contenedorNavegacion{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    margin-top: 0;
}

.contenedorNavBar li{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    list-style: none;
    padding: 0 20px
}

.contenedorNavBar li:hover{
    border-bottom: none;
}

.itemNavbar:hover{
    color: #3981bf;
}

.itemNavbar{
    text-decoration: none;
    font-size: 1rem;
    color: black;
    font-weight: bold;
}

.imgMenu{
    height: 13vh;
    padding: 5px 50px;
}

/*  style Carrusel   */
.contenedorCarrusel{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 55vh;
    
}

.imgFondo1{
    background-image: url('https://i.imgur.com/eMOiqtz.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    filter: brightness(1.1);
}

.imgFondo2{
    background-image: url('https://i.imgur.com/iYvlPwA.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    filter: brightness(1);
}

.imgFondo3{
    background-image: url('https://i.imgur.com/V49QqgY.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    filter: brightness(1.1);
}

.contenedorCarrusel h3{
    font-size: 2rem;
    color: #b5d6ff;
    text-shadow: 2px 2px 3px #000000;
    padding: 3rem;
    position: absolute;
}

.textCarrusel{
    text-align: center;
    font-size: 0.8rem;
    padding: 0 15px;
}

.item{
    transform: translateY(-1rem);
    font-size: 0.8rem;
    border-bottom: 2px solid #3981bf;
}

.item:hover{
    border-bottom: none;
    cursor: pointer;
}

@media (min-width: 897px)  {
    .contenedorCarrusel h3{
        font-size: 2.7rem;
    }
}

@media (max-width: 700px)  {
    .contenedorCarrusel h3{
        font-size: 1rem;
    }
    .contenedorNavBar{
        padding: 0px;
        margin: 0;
    }

    .contenedorCarrusel{
        height: 30vh;
    }
    .imgMenu{
        height: 5vh;
    }
    .itemNavbar{
        font-size: 0.5rem;
    }
}