.contenedorTextoSeccion{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    margin: 3rem 5rem;
    color: #3981bf;
    font-size: 1.5rem
}
.textoMedio{
   color: #3981bf;
}

@media (max-width: 897px)  {
    
}

@media (max-width: 700px)  {
    .contenedorTextoSeccion{
        margin: 2.5rem 2rem;
        font-size: 1rem;
    }
    .textoMedio{
        padding: 0 1rem;
    }
}