.contacto{
    background-color: rgb(0, 0, 0);
}

.tituloContacto{
    text-align: center;
    color: #fff;
    font-size: 2rem;
    padding-top: 2rem;
    font-weight: bold;
}

.contenedorContacto{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 2rem;
}

.contenedorForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.FormFila{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0.5rem 2rem;
}

input[type='text'], 
input[type='email'], 
input[type='subject'],
input[type='number']{
    margin-top: 0.2rem;
    margin-bottom: 0.8rem;
    width: 35vw;
    height: 1.3rem;
    border: none;
    border-bottom: 2px solid #fff;
    outline: none;
    background-color: rgb(0, 0, 0);
    color: #fff;
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.FontAwesomeIcon{
    color: #fff;
    height: 1.5rem;
    transition: 0.5s;
}

.FontAwesomeIconPhone{
    color: #3981bf;
    height: 0.6rem;
}

.FontAwesomeIcon:hover{
    color: #3981bf;
}

.contenedorError{
    height: 5vh;
}

.mensajeError{
    font-size: 0.5rem;
    color: red;
    text-align: start;
    font-style: italic;
    font-weight: bold;
}

input::placeholder{
    color:#fff;
}

input:focus{
    border-bottom: 2px solid #3981bf;
}

textarea{
    font-family: 'Montserrat', sans-serif;
    resize: none;
    width: 35vw;
    border: none;
    border-bottom: 2px solid #fff;
    outline: none;
    background-color: rgb(0, 0, 0);
    color: #fff;
}

textarea::placeholder{
    color: #fff;
}

textarea:focus{
    border-bottom: 2px solid #3981bf;
}

.FormFilaText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0.5rem 2rem;
}

.inputText{
    height: 10vh;
}

.contenedorContactoPhone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 2rem;
}

.contenedorContactoForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contenedorPhone{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 45vw;
}

.contenedorPhones{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2rem;
}

.Phones{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.PhonesWhatsapp{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-decoration: none;
}

.PhonesWhatsapp p{
    transition: all 0.5s;
}

.PhonesWhatsapp p:hover{
    color: #25d366;
}

.tituloPhone{
    font-size: 1rem;
    color: #fff;
    padding-left: 1rem;
    margin-bottom: 0.5rem;
}

.subtituloPhone{
    font-size: 0.8rem;
    color: #fff;
    margin-top: 0;
    margin-bottom: 0.3rem;
    padding-left: 1rem;
}

.contenedorButtonContacto{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 2rem;
    width: 80%;
}

.buttonContacto{
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 0.2rem;
    width: 5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-top: 1rem;
    transition: 0.5s;
}

.buttonContacto:hover{
    background-color: #3981bf;
    color: #fff;
}

.botonPresionado{
    color:rgb(255, 255, 255);
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 0.3rem 0.8rem;
    margin: 0;
}

.contenedorBotonPresionado{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textoBotonPresionado{
    color: #fff;
    font-size: 0.5rem;
}

@media (min-width: 1080px)  {
    input{
        margin-bottom: 1rem;
        width: 35vw;
        height: 1.5rem;
    }   
    .contenedorContactoPhone h3{
        font-size: 1rem;
        margin: 0;
    }
    .contenedorContactoPhone p{
        font-size: 0.8rem;
    }
}

@media (min-width: 897px)  {
    input[type='text'], 
    input[type='email'], 
    input[type='subject'],
    input[type='number'],
    textarea{
        margin-bottom: 1rem;
    }
    .FormFila input{
        height: 4vh;
        font-size: 1.2rem;
    }
    .inputText{
        height: 10vh;
    }
    .contenedorContactoPhone h3{
        font-size: 0.8rem;
    }
    .contenedorContactoPhone p{
        font-size: 0.8rem;
        margin: 0.5rem 0;
    }
}

@media (max-width: 700px){
    .contenedorContacto{
        flex-direction: column;
    }
    .contenedorPhone{
        width: 85%;
        margin: 2rem;
    }
    .contenedorForm{
        width: 100%;
    }
    input[type='text'], 
    input[type='email'], 
    input[type='subject'],
    input[type='number'],
    textarea{
        width: 80vw;
    }
}